import { translate } from '@/util/commonFun'

// 日期选择预设范围
export const pickerOptions = {
  shortcuts: [
    {
      text: translate('historyPage.lastWeek'),
      onClick(picker: any) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        picker.$emit('pick', [start, end])
      },
    },
    {
      text: translate('historyPage.lastMonth'),
      onClick(picker: any) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        picker.$emit('pick', [start, end])
      },
    },
    {
      text: translate('historyPage.lastSeason'),
      onClick(picker: any) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
        picker.$emit('pick', [start, end])
      },
    },
  ],
}

// 不同设备对应的保存接口
export enum EquipmentSaveApis {
  // 雕刻机
  'engraving' = 'save_engraving_setting',
  // 玻璃切割机
  'glassEquip' = '/save_glass_setting',
  // 电子锯
  'electronicSaw' = '/save_electronic_saw_setting',
  //五六面钻
  'dock56FDrill' = '/save_dock56F_setting',
  // 侧孔机
  'sideHoleDrill' = '/save_side_hole_machine_setting',
  // PTP
  'ptpHoleDrill' = '/save_ptp_setting',
}

// 不同设备的保存接口接收到的参数不一致
export const needData = [
  'engraving',
  'glassEquip',
  'dock56FDrill',
  'sideHoleDrill',
]

// 表格数据变更类型
export const tableChangeType = {
  edit: '编辑',
  add: '新增',
  delete: '删除',
}

export const notNeedData = ['electronicSaw', 'ptpHoleDrill']
