type ObjToKey<T> = keyof T
//原片管理-特别材质
const specialMaterialTable = [
  'matCode',
  'color',
  'thick',
  'width',
  'height',
  'isPicked',
  'id',
]

//开料机设置=孔槽
const holeSlotTable = ['name', 'Depth', 'Width', 'Diameter']

//开料机-刀具库
const knifeStoreTable = [
  'desc',
  'name',
  'cutable',
  'diameter',
  'use',
  'moveZF',
  'cutZF',
  'moveF',
  'cutF',
  'startCode',
  'stopCode',
  'startCode2',
  'stopCode2',
  'preStartCode',
]

//雕刻机-排钻设置
const knifeToolTable = [
  'isUsed',
  'number',
  'number2',
  'diameter',
  'xOffset',
  'yOffset',
  'moveZF',
  'feedRate',
  'moveF',
  '_selected',
]

//雕刻机切割参数
const cuttingParamsPlankTable = [
  'matCode',
  'highlight',
  'pThick',
  'firstCut',
  'secondCut',
  'thirdCut',
]
//贴标设置-排版图设置
const layoutSettingsTable = [
  'enable',
  'imageNamePrefix',
  'imageSize',
  'imageFormat',
  'imageDirection',
]

//电子据自定义表格
const customerFormTable = ['importHeader', 'headerString']

//侧孔机刀具库
const sideHoleKnifeStoreTable = [
  'id',
  'diameter',
  'xOffset',
  'yOffset',
  'zOffset',
  'startCode',
  'endCode',
]

//侧孔机正面加工刀具库
const sideHoleFrontKnifeStoreTable = [
  'id',
  'knifePurpose',
  'diameter',
  'x_offset',
  'y_offset',
  'z_offset',
  'startCode',
  'endCode',
]

//PTP刀具库
const ptpKnifeStoreTable = [
  'id',
  'type',
  'diameter',
  'offsetX',
  'offsetY',
  'offsetZ',
  'offsetNo',
  'cutVelocity',
  'drillVelocity',
  'rotationlSpeed',
]

// 门套机表格数据
const mentaojiSawTable = [
  'serial_num',
  'saw_width',
  'saw_direction',
  'cut_from',
  'move_speed',
  'cut_speed',
  'start_code',
  'end_code',
  'saw_down_code',
  'saw_up_code',
  'abscissa',
  'ordinate',
]

// 从孔列表数据
const sideHoleDrillSettingDrillCombinationFromHoleTable = [
  'name',
  'knifePrefix',
  'knifeSuffix',
]

// 侧孔机旧版设置自定义1
const sideHoleOldKnifeStoreTableV1 = [
  'id',
  'diameter',
  'type',
  'moveSpeed',
  'doSpeed',
  'startCode',
  'endCode',
]

// 侧孔机旧版设置自定义3
const sideHoleOldKnifeStoreTableV3 = [
  'knifeName',
  'type',
  'diameter',
  'moveSpeed',
  'doSpeed',
  'startCode',
  'endCode',
]

// 侧孔机旧版设置自定义4
const sideHoleOldKnifeStoreTableV4 = [
  'knifeName',
  'diameter',
  'startCode',
  'endCode',
]

//一体机-刀具库
const modelAioTable = [
  'name',
  'diameter',
  'use',
  'moveZF',
  'cutZF',
  'moveF',
  'cutF',
  'xOffset',
  'yOffset',
  'zOffset',
  'startCode',
  'stopCode',
]
// 雕刻机切割参数特殊孔槽自定义加工面
const custom_special_hole_slot_side = ['specialHoleSlot', 'side']
const tableColumns = {
  special_material_table: specialMaterialTable, //原片管理-特别材质
  hole_slot_table: holeSlotTable, //开料机设置=孔槽
  knife_store_table: knifeStoreTable, //开料机-刀具库
  knife_tool_table: knifeToolTable, //雕刻机-排钻设置
  cutting_params_plank_table: cuttingParamsPlankTable, //雕刻机切割参数
  custom_special_hole_slot_side_table: custom_special_hole_slot_side, //雕刻机切割参数特殊孔槽自定义加工面
  layout_settings_table: layoutSettingsTable, //贴标设置-排版图设置
  customer_form_table: customerFormTable, //电子据自定义表格
  side_hole_knife_store_table: sideHoleKnifeStoreTable, //侧孔机刀具库
  side_hole_front_knife_store_table: sideHoleFrontKnifeStoreTable, //侧孔机正面加工刀具库
  ptp_knife_store_table: ptpKnifeStoreTable, //PTP刀具库
  mentaoji_saw_table: mentaojiSawTable, // 开料机-门套机表格
  side_hole_drill_setting_drill_combination_from_hole_table:
    sideHoleDrillSettingDrillCombinationFromHoleTable, // 雕刻机-排钻设置-组合下刀-从孔列表
  side_hole_old_knife_store_table_v1: sideHoleOldKnifeStoreTableV1, // 侧孔机-旧版设置-自定义1
  side_hole_old_knife_store_table_v3: sideHoleOldKnifeStoreTableV3, // 侧孔机-旧版设置-自定义3
  side_hole_old_knife_store_table_v4: sideHoleOldKnifeStoreTableV4, // 侧孔机-旧版设置-自定义4
  model_aio_table: modelAioTable, // 一体机设置-刀具
}
tableColumns.hole_slot_table.filter((it) => it === 'Depth')
export default function getTableColumns(type: ObjToKey<typeof tableColumns>) {
  return tableColumns[type]
}
